<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 class="cpx-4" style="padding-top: 15px; padding-bottom: 15px">
        カテゴリ登録
      </h2>
      <form
        class="px-3"
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <!-- <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >NO</label
          >
          <div class="cp-4 w-100">
            <b-form-input v-model="NO" disabled></b-form-input>
          </div>
        </div> -->
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >カテゴリ名<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="category_name"
              v-bind:class="{ 'error-input': categoryNameRequired }"
            />
          </div>
          <div class="message-error" v-if="categoryNameRequired">
            {{ feedback.REQUIRED }}
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center pb-4">
        <button
          v-on:click.prevent="UpdateNewCategory()"
          class="mr-4 btn-save sale-btn"
        >
          登録
        </button>
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { FeedBack } from "@/utils/feedback.js";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      feedback: FeedBack,
      no: "",
      category_name: "",
      categoryNameRequired: false,
      NO: this.$route.query.NO ? this.$route.query.NO : this.$route.query.total,
      shopId: localStorage.getItem(Constants.SHOP_ID),
      isEdit: false,
    };
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.isEdit = true;
      const requestContent = {
        id: id,
        shop_id: this.shopId,
      };
      this.getCategoryNewById(requestContent);
    }
  },
  computed: {
    ...mapGetters(["success", "message", "error", "detailCategoryNew"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.push({
          name: this.$route.params.shopId
            ? "list category new"
            : "list category new domain",
        });
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    detailCategoryNew() {
      this.category_name = this.detailCategoryNew.title;
    },
    category_name() {
      this.categoryNameRequired = false;
    },
  },
  methods: {
    ...mapActions({ updateCategoryNew: "updateCategoryNew" }),
    ...mapActions({ getCategoryNewById: "getCategoryNewById" }),
    ...mapActions({ createCategoryNew: "createCategoryNew" }),
    async UpdateNewCategory() {
      let error;
      if (!this.category_name) {
        this.categoryNameRequired = true;
        error = true;
      }
      if (!error) {
        const formCreateCategory = {
          shop_id: this.shopId,
          title: this.category_name,
          id: this.$route.params.id ? this.$route.params.id : "",
        };
        if (this.isEdit) {
          this.updateCategoryNew(formCreateCategory);
        } else {
          this.createCategoryNew(formCreateCategory);
        }
      }
    },
    returnList() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "list category new"
          : "list category new domain",
      });
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
</style>
